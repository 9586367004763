import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/global/layout/layout";
import Seo from "../components/global/seo/seo";

const About = () => {
  return (
    <Layout>
      <Seo title="About" />
      <div className="about padded">
        <h1 className="page-title">
          We are a <br /> design <br /> community
        </h1>

        <div className="about-content">
          <div className="col-1">
            <h5>Our mission.</h5>
            <p>
              Design Burger was born out of the desire to curate and promote the
              very best Industrial Design, inspire a global design community and
              drive innovation within the industry.
            </p>

            <h5>Who we are?</h5>
            <p>
              Founded in 2018, our tight-knit, dedicated team is committed to
              always being at the forefront of the latest trends in design. We
              work consistently to bring you, the design community, the most
              exciting, innovative work out there. Our critical eye and
              understanding of aesthetics are at our very core, influencing both
              what we curate and create.
            </p>

            <h5>What we do.</h5>
            <p>
              The ever-growing Design Burger platform provides an opportunity
              where consultancies, independent designers and students can
              present the very best of their work. Studio Sessions establishes
              tangible connections between the community and global
              consultancies by revealing the fundamental principles behind each
              company and their creations. Design Dialogues presents an informal
              sketch style interview with world-renown designers, allowing you
              an insight into the personalities behind your favourite products.
            </p>
          </div>

          <div className="col-2">
            <h5>Why we do it.</h5>
            <p>
              Talented designers are at the heart of what motivates us. You fuel
              our desire to keep us sharing new, engaging content that aims to
              inspire other designers to keep developing great work.
            </p>

            <h5>What’s next?</h5>
            <p>
              We are now looking to take our Industrial Design skill set,
              marketing abilities and great community following to work with
              brands we love, to create best-selling products and content for
              our community.
            </p>
          </div>
        </div>

        <h5 className="about-logos-title">
          We’ve had the pleasure of working with…
        </h5>
        <div className="about-logos">
          <StaticImage src="../images/logos/Copic.png" className="about-logo" />
          <StaticImage
            src="../images/logos/Almond.png"
            className="about-logo"
          />
          <StaticImage
            src="../images/logos/Ammunition.png"
            className="about-logo"
          />
          <StaticImage src="../images/logos/Blond.png" className="about-logo" />
          <StaticImage
            src="../images/logos/Arrival.png"
            className="about-logo"
          />
          <StaticImage
            src="../images/logos/b-and-o.png"
            className="about-logo"
          />
          <StaticImage
            src="../images/logos/Keyshot.png"
            className="about-logo"
          />
          <StaticImage
            src="../images/logos/Dezeen.png"
            className="about-logo"
          />
          <StaticImage
            src="../images/logos/e-and-s.png"
            className="about-logo"
          />
          <StaticImage
            src="../images/logos/Vitamin.png"
            className="about-logo"
          />
          <StaticImage
            src="../images/logos/Yellow-Images.png"
            className="about-logo"
          />
          <StaticImage src="../images/logos/Gro.png" className="about-logo" />
          <StaticImage src="../images/logos/Noho.png" className="about-logo" />
          <StaticImage src="../images/logos/Bebop.png" className="about-logo" />
          <StaticImage
            src="../images/logos/Design-London.png"
            className="about-logo"
          />
          <StaticImage
            src="../images/logos/b-and-w.png"
            className="about-logo"
          />
          <StaticImage
            src="../images/logos/Cloud-co.png"
            className="about-logo"
          />
          <StaticImage src="../images/logos/Crux.png" className="about-logo" />
          <StaticImage src="../images/logos/GIDA.png" className="about-logo" />
          <StaticImage src="../images/logos/Layer.png" className="about-logo" />
          <StaticImage
            src="../images/logos/Morrama.png"
            className="about-logo"
          />
          <StaticImage src="../images/logos/Noto.png" className="about-logo" />
          <StaticImage
            src="../images/logos/Design-Partners.png"
            className="about-logo"
          />
          <StaticImage
            src="../images/logos/Bspoke365.png"
            className="about-logo"
          />
          <StaticImage src="../images/logos/Zuma.png" className="about-logo" />
        </div>

        <p>
          Get in touch{" "}
          <Link to="/contact" className="link">
            here...
          </Link>
        </p>
      </div>
    </Layout>
  );
};

export default About;
